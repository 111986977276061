import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AllPageimport from './Pages/AllPageimport/AllPageimport';
import Contact from './Pages/Contact/Contact';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<AllPageimport />} />
        <Route path="/Contact" element={<Contact />} />
        
      </Routes>
    </BrowserRouter>
   
    </div>
  );
}

export default App;
