import React from "react";
import Footer from "../../Component/Footer/Footer";
import Home from "../Home/Home";
import Rating from "../Rating/Rating";
import Header from "../../Component/Header/Header";
import Swiperimg from "../Swiperimg/Swiperimg";
import Contact from "../Contact/Contact";

const AllPageimport = () => {
  return (
    <div>
     <Header />
      <Home />
      
      <Rating />

      <Swiperimg />
      <Contact />
      <Footer />
    </div>
  );
};

export default AllPageimport;
