import React from "react";
import "./Videowithtext.css";
import backvideo from "../assets/vid/home.mp4";
import logo1 from "../assets/Tuni full logo (1).svg";

const Videowithtext = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-12 col-md-12 col-12 bigvideo">
            <video src={backvideo} autoPlay loop muted />
            <div className="">
             
              {/* <div className="logo_size d-flex">
                <img src={logo1} alt="Logo" className="logo  img-fluid" />
              </div> */}

              <div className="content text_content">
                <h1 data-aos="fade-up" data-aos-duration="3000"></h1>
                <span className="text-center animation_text_back_color">
                  TUNi is an unique AI based E-commerce Mobile Application which
                  allows a combo based shopping for adults with an Virtual AI
                  Stylist Expert.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Videowithtext;
