import { Link } from "react-router-dom";
import "./Footer.css";

import Img1 from "../assets/1.jpg";
import Img2 from "../assets/2.jpg";
import Img3 from "../assets/3.jpg";
import Img4 from "../assets/7.jpeg";
import Img5 from "../assets/5.jpg";
import Img6 from "../assets/6.jpeg";
import Img7 from "../assets/8.jpg";
import Img8 from "../assets/9.webp";
import React, { useEffect } from "react";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";
const Footer = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="container-fluid back_color">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div class="single-box font_weight_con mx-3">
              <h3 className="text__success pt-2 fw-bold my-2">
                Tuni Tech Solutions Private Limited
              </h3>
              <p className="py-3">
                <h4 className="my-1">
Corporate Office :</h4>
                <i class="bi bi-geo-alt-fill text-primary"></i>
                Block B, BRIGADE TECH PARK, Pattandur Agrahara,<br /> Whitefield,
                Bengaluru, Karnataka 560066
              </p>
              <p>
<h4>Reach Us at :</h4>

reach@tunitechsolutions.com</p>
              <p class="socials class_hover">
                <Link to="https://www.youtube.com/" target="_blank">
                  <i class="bi bi-youtube mx-2  text_back   fs-5"></i>
                </Link>
                <Link to="https://www.instagram.com/" target="_blank">
                  <i class="bi bi-instagram  text_back  mx-2 fs-5"></i>
                </Link>
                <Link to="https://www.facebook.com/" target="_blank">
                  <i class="bi bi-facebook text_back mx-2  fs-5"></i>
                </Link>
                <Link to="https://web.whatsapp.com/" target="_blank">
                  <i class="bi bi-whatsapp text_back mx-2  fs-5"></i>
                </Link>
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12 ">
            <div class="footer_column mx-2 py-5 ">
              <div className="img_overall text-center">
                <div className="img_bar">
                  <img src={Img1} alt="im" data-aos="zoom-in-right"></img>
                </div>
                <div className="img_bar">
                  <img src={Img3} alt="im" data-aos="flip-right"></img>
                </div>
                <div className="img_bar">
                  <img src={Img2} alt="im" data-aos="zoom-in-down"></img>
                </div>
                <div className="img_bar">
                  <img src={Img7} alt="im" data-aos="zoom-in-down"></img>
                </div>
              </div>
              <div className="img_overall">
                <div className="img_bar">
                  <img
                    src={Img4}
                    alt="im"
                   
                   
                  ></img>
                </div>
                <div className="img_bar">
                  <img src={Img5} alt="im" ></img>
                </div>
                <div className="img_bar">
                  <img
                    src={Img6}
                    alt="im"
                  
                  ></img>
                </div>
                <div className="img_bar">
                  <img
                    src={Img8}
                    alt="im"
                 
                  
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
