import React from 'react'
import Marquee from 'react-fast-marquee';
import Ads1 from "./assets/2.jpg"
 import Ads2 from "./assets/images.jpeg"
 import Ads3 from "./assets/shopping (1).webp"
 import Ads4 from "./assets/6.jpg"
 import Ads5 from "./assets/7.jpeg"
 import Ads6 from "./assets/8.jpg"
 import Ads7 from "./assets/9.jpeg"
 import Ads8 from "./assets/10.jpeg"
 import Ads9 from "./assets/12.jpeg"
 import Ads10 from "./assets/13.jpg"
import "./Swiperimg.css";

const Swiperimg = () => {
  return (
    <>
    <div className="container-fluid wave-animation">
        <div className="row">
          <h3 className="d-flex justify-content-center text-white fw-bold font_family mt-2 mb-2">
            FEATURED ON
          </h3>
          <Marquee>
            <div className="col-md-12 ads_img_size d-flex flex-wrap mb-3 my-4 text-center text_img_center">
              <img
                src={Ads1}
                alt="ss"
                className="mx-auto col-lg-1 col-md-2 col-sm-6 mb-3 mx-5"
              ></img>
              {"\u00a0"}
              {"\u00a0"}
              <img
                src={Ads2}
                alt="ss"
                className="mx-auto col-lg-1 col-md-2 col-sm-6 mb-3 mr-3"
              ></img>
              {"\u00a0"}
              {"\u00a0"}
              <img
                src={Ads3}
                alt="ss"
                className="mx-auto col-lg-1 col-md-2 col-sm-6 mb-3 mr-3"
              ></img>
              {"\u00a0"}
              {"\u00a0"}
              <img
                src={Ads4}
                alt="ss"
                className="mx-auto col-lg-1 col-md-2 col-sm-6 mb-3 mr-3"
              ></img>
              {"\u00a0"}
              {"\u00a0"}
              <img
                src={Ads5}
                alt="ss"
                className="mx-auto col-lg-1 col-md-2 col-sm-6 mb-3 mr-3"
              ></img>
              {"\u00a0"}
              {"\u00a0"}
              <img
                src={Ads6}
                alt="ss"
                className="mx-auto col-lg-1 col-md-2 col-sm-6 mb-3 mr-3"
              ></img>
              {"\u00a0"}
              {"\u00a0"}
              <img
                src={Ads7}
                alt="ss"
                className="mx-auto col-lg-1 col-md-2 col-sm-6 mb-3 mr-3"
              ></img>
              {"\u00a0"}
              {"\u00a0"}
              <img
                src={Ads8}
                alt="ss"
                className="mx-auto col-lg-1 col-md-2 col-sm-6 mb-3 mr-3"
              ></img>
              {"\u00a0"}
              {"\u00a0"}
              <img
                src={Ads9}
                alt="ss"
                className="mx-auto col-lg-1 col-md-2 col-sm-6 mb-3 mr-3"
              ></img>
              {"\u00a0"}
              {"\u00a0"}
              <img
                src={Ads10}
                alt="ss"
                className="mx-auto col-lg-1 col-md-2 col-sm-6 mb-3"
              ></img>
            </div>
          </Marquee>
        </div>
        <br />
       

        <br />
      </div>

      
    </>
  )
}

export default Swiperimg
