import React from "react";
import "./Header.css";

import logo1 from "../../Pages/Home/assets/Tuni full logo (1).svg";
const Header = () => {
  return (
    <>
      <nav className="navbar bg-body-tertiary navbar_margin">
        <div className="container-fluid back_col">
          <div className="logo_size">
            {" "}
            <a className="navbar-brand1">
              {" "}
              <img src={logo1} alt="" className="img-fluid" />{" "}
            </a>
          </div>

          <div className="d-flex back_col1">
          <button
        className="desn border-0 "
        onClick={() => {
          document
            .getElementById("contact1")
            .scrollIntoView({ behavior: "smooth" });
        }}
      >Contact
      </button>
          </div>
        </div>
        
      </nav>
    </>
  );
};

export default Header;
