import { useEffect } from "react";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

import "./Contact.css"
import Contactimg1 from "./Assets/example-14.png"

import React ,{ useRef } from 'react'
import emailjs from '@emailjs/browser';

const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, []);



  const form = useRef();
    // 
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_drnze5f', 'template_pl7ix2o', form.current, 'BrVJuhmqBMVrTO8W2')
          .then((result) => {
              console.log(result.text);

              e.target.reset();

              alert('Email Send...')
             }, (error) => {
                console.log(error.text);
                alert("can't send...")
             });
      };


  return (
    <>
   <div className='container' id="contact1">
    <div className='row'>
        <h1 className='text-center py-4 contact_color'>Contact</h1>
        <div className='col-lg-6 col-md-6 col-12'>
        <div className="card-body ">
      <form  ref={form}  onSubmit={sendEmail}>
                <div className="mb-3">
                  <input
                    type="name"
                    placeholder="Enter name"
                    className="form-control rounded-pill p-3"
                    name='your_name'                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    placeholder="Enter email"
                    className="form-control rounded-pill p-3"
                    name='your_email'
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    placeholder="Enter number"
                    className="form-control rounded-pill p-3"
                    maxLength={10}
                    name="your_number"
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    placeholder="Message goes here..."
                    type="text"
                    className="form-control rounded p-3"
                    rows="5"
                    name="message" 
                  ></textarea>
                </div>
                <div  className='send_button'>
                <button
                  type="submit"
                  value="send"
                  className="btn btn-save w-50 w-md-50 border "
                >
                  Send
                </button>
                </div>
                </form>
              </div>
        </div>
        <div className='col-lg-6 col-md-6 col-12'>
            <div className='contactimg'>
            <img src={Contactimg1} alt="Contactimg1" className='img-fluid' data-aos="zoom-out"data-aos-duration="2000"/>
            </div>

        </div>
    </div>
   </div>
      
    </>
  )
}

export default Contact
