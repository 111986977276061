import React from "react";
import "./videos2.css";
import backvideo1 from "../assets/vid/rewards-desktop-final.mp4";
const videos2 = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-12 col-md-12 col-12 bigvideo1">
            <video src={backvideo1} autoPlay loop muted />
            <div className="text_content1">
              <div className="content">
                 <span className="text-center fw-bold">
                  Feel the odds fall <br /> in your favor
                 </span>
                 <p className="mt-2 px-5 mx-5 py-3 text-center">
                  we can offer a highly personalized shopping experience that
                  helps users discover new styles, make confident purchasing
                  decisions, and stay up-to-date with the latest fashion trends.{" "}
                 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default videos2;
