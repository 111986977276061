import React from "react";
import Videowithtext from "./Videowithtext/Videowithtext";
import Video2 from "./videos2/videos2";
import "./Home.css";

import img1 from "../Home/assets/user.gif";
import img2 from "../Home/assets/artificial-intelligence.gif";
import img3 from "../Home/assets/best-customer-experience.gif";
import img4 from "../Home/assets/growth.png";
import img5 from "../Home/assets/book-recommendation.gif";
import img6 from "../Home/assets/feedback-loop.gif";
import img7 from "../Home/assets/user.gif";

const Home = () => {
  return (
    <>
      <div className="video1">
        <Videowithtext />
      </div>
      <div className="container">
        <div className="row shadow-lg my-5">
          <div className="col-lg-3 col-md-6 col-12 p-2 color_text1">
            <div className="px-1 my-5">
            <h2>
            What's special <br />on TUNi  : :
            </h2>
          
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 color_text">
            {/* <i class="bi bi-wallet2 fw-bold fs-4 text-primary"></i> */}
            <div className="d-flex">
              {" "}
              <h3>Style Quiz: <img src={img1} className="img-fluid px-1"></img></h3>{" "}
             
            </div>
            <p>
              Users answer a series of questions about their fashion
              preferences, body shape, favorite brands, and occasions they
              typically dress for. The AI then uses this information to
              understand their style profile
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-12 color_text">
            {/* <i class="bi bi-wallet2 fw-bold fs-4 text-primary"></i> */}
            <div className="d-flex">
              {" "}
              <h3>Virtual Try-On: <img src={img2} className="img-fluid px-1"></img></h3>{" "}
             
            </div>
            <p>
              Utilize augmented reality (AR) technology to allow users to
              virtually try on clothes before making a purchase. Users can see
              how items would look on them in real-time using their device's
              camera.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-12 color_text">
            {/* <i class="bi bi-wallet2 fw-bold fs-4 text-primary"></i> */}
            <div className="d-flex">
              {" "}
              <h3>Mix and Match: <img src={img3} className="img-fluid px-1"></img></h3>{" "}
              
            </div>
            <p>
              The Virtual Stylist could suggest outfit combinations based on
              items the user already owns or items they are considering
              purchasing. It could even recommend accessories and shoes to
              complete the look.
            </p>
          </div>

          <div className="col-lg-3 col-md-6 col-12 p-2 color_text1"></div>
          <div className="col-lg-3 col-md-6 col-12 color_text">
            {/* <i class="bi bi-wallet2 fw-bold fs-4 text-primary"></i> */}
            <div className="d-flex">
              {" "}
              <h3>Trend Alerts: <img src={img4} className="img-fluid px-1"></img></h3>{" "}
              
            </div>
            <p>
              Keep users informed about the latest fashion trends and how they
              can incorporate them into their wardrobe. The AI could analyze
              social media, fashion blogs, and celebrity styles to provide
              real-time trend updates.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-12 color_text">
            {/* <i class="bi bi-wallet2 fw-bold fs-4 text-primary"></i> */}
            <div className="d-flex">
              {" "}
              <h3>Seasonal: <img src={img5} className="img-fluid px-1"></img> </h3>{" "}
              
            </div>
            <p>
              Offer seasonal wardrobe updates and recommendations based on the
              user's location and the current weather forecast. For example,
              suggest lightweight fabrics and breathable clothing during hot
              summers or layering options for colder climates.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-12 color_text">
            {/* <i class="bi bi-wallet2 fw-bold fs-4 text-primary"></i> */}
            <div className="d-flex">
              {" "}
              <h3>Feedback: <img src={img6} className="img-fluid px-1"></img></h3>{" "}
             
            </div>
            <p>
              Allow users to provide feedback on the recommendations they
              receive, helping the AI learn and refine its suggestions over time
              to better suit individual tastes and preferences.
            </p>
          </div>
        </div>
      </div>
      {/* ddddd */}
      <Video2 />
      <br />
    </>
  );
};

export default Home;
