import Star from "../Rating/assets/4.5stars.svg";
import Star2 from "../Rating/assets/4stars.svg";
import "./Rating.css";
import GooglePlay from "././assets/google.png";
import ApplyPlay from "././assets/apple.png";

import React, { useEffect } from "react";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

const Rating = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="container">
        <div className="text-center rating_heading">
          <br />
          <br />
          <br />
          <h1 className="fs-1">Ratings</h1>
          <p className="fw-bold text-muted">Last updated on: 20th Apr 2024</p>
          <br />
        </div>
        <div className="row mb-4">
          <div className="col-md-2"></div>

          <div className="col-md-3 col-12 mb-4">
            <div
              class="card shadow-lg border-0"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <div>
                <span className="rating_num fs-1 px-2">4.1</span> <br />{" "}
                <span className="fw-bold blue-collar text-center mx-1 fs-2 mb-2">
                  Google Reviews
                </span>
              </div>
              <br />
              <img src={Star} alt="star" />
            </div>
          </div>

          <div className="col-md-3 col-12 mb-4">
            <div
              class="card shadow-lg border-0"
              id="google-review-card-style"
              data-aos="flip-up"
              data-aos-duration="3000"
            >
              <div>
                <span className="rating_num fs-1 px-2">4.4</span> <br />{" "}
                <span className="fw-bold blue-collar text-center mx-1 fs-2">
                  Play store
                </span>
              </div>
              <br />
              <img src={Star2} alt="star" />
            </div>
          </div>

          <div className="col-md-3 col-12 mb-4">
            <div
              class="card shadow-lg border-0"
              id="google-review-card-style"
              data-aos="flip-right"
              data-aos-duration="3000"
            >
              <div>
                <span className="rating_num fs-1 px-2">4.0</span> <br />
                <span className="fw-bold blue-collar text-center mx-1 fs-3">
                  APP <span className="">Store</span>
                </span>
              </div>
              <br />
              <img src={Star} alt="star" />
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="row d-flex justify-content-center text-center ">
          <div className="col">
            <a
              href="https://play.google.com/store/games?utm_source=apac_med&hl=en-IN&utm_medium=hasem&utm_content=Jun1223&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Jun1223-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700076783011871_creativeid_662052581180_device_c&gclid=Cj0KCQiAmNeqBhD4ARIsADsYfTfIVPkbM89GcceD9y4Ea2Qt_nyVxcr16UtE0DyRTe8pdo6IQKmwOUEaAm-TEALw_wcB&gclsrc=aw.ds"
              alt="google"
              target="_blank"
            >
              {" "}
              <img
                src={GooglePlay}
                alt="playstore"
                className="p-2 img-fluid"
                data-aos="fade-right"
                data-aos-duration="2000"
              ></img>
            </a>
            <a
              href="https://www.apple.com/in/app-store/"
              alt="apple"
              target="_blank"
            >
              {" "}
              <img
                src={ApplyPlay}
                alt="playstore"
                className="img-fluid"
                data-aos="fade-left"
                data-aos-duration="2000"
              ></img>
            </a>
          </div>
        </div>
      </div>
      <br />

      {/* <div className="container mt-3 py-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="text-center my-1 about_name_color fw-bold fs-2 mb-3 pb-2">
                About Us
              </h2>
              <p className="text-center fs-5 text-muted">
                We are India’s first 100% digital Shop app that allows you to
                earn high returns of up to 10% annually,
              </p>
              <p className="text-center fs-5 text-muted">
                as well as borrow affordably in just a few easy steps. We are a
                completely transparent chit firm and it reflects in the way we
                do our auctions and payouts.
              </p>
              <p className="text-center fs-5 text-muted mt-2 mb-4">
                Start your savings journey today!
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Rating;
